import React from "react";
import "./../index.scss";
import { Header, Footer, Contact } from "./../components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";
// import AliceCarousel from "react-alice-carousel";
import gif from "./../images/slider-2.gif";

export default ({ data }) => {
  // const items = [
  //   <div>
  //     <Img fluid={data.slider1.childImageSharp.fluid} />
  //   </div>,
  // ];

  return (
    <>
      <Helmet>
        <title>Showelli | Bespoke Entertainment</title>
        <meta name="description" content="Showelli events and TODO" />
        <meta
          name="keywords"
          content="Showelli, dancers, performers, choreography, music videos, concerts, backup dancers, entertainment, fire performers"
        />
      </Helmet>
      <Header />
      <div className="hero is-medium is-black has-extra-margin-bottom has-background-image">
        {/* <AliceCarousel
          items={items}
          infinite={true}
          keyboardNavigation={false}
        /> */}
        <div className="background-image">
          <Img
            fluid={data.slider1.childImageSharp.fluid}
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "auto",
              opacity: 0.6,
            }}
          />
        </div>
        <div className="hero-body">
          <h2 className="title has-text-centered">Showelli</h2>
          <h1 className="title has-text-centered">Entertainment</h1>
          <h3 className="subtitle has-text-centered">Bespoke Performers</h3>
        </div>
      </div>
      <section id="about-us" className="section">
        <div className="container">
          <div className="title banner-title has-text-centered">
            About Us
            {/* <div className="gatsby-image-container">
              <Img
                fluid={data.logo.childImageSharp.fluid}
                alt="Showelli circle logo"
              />
            </div> */}
          </div>
          <div className="columns is-vcentered is-reversed">
            <div className="column">
              <div className="img-container is-in-title">
                <img
                  src={gif}
                  alt="Playhxuse showelli"
                  title="Showelli jungle customes with flowers for showelli playhxuse"
                />
              </div>
            </div>
            <div className="column is-half">
              <div className="content is-size-5 has-text-centered">
                <p>
                  Showelli goes that extra mile to provide bespoke entertainment
                  with our beautiful, exclusive performers and artists giving
                  you a spectacular show at your event!
                </p>
                <br />
                <p>
                  We supply your event with our expert dancers, fire performers,
                  DJs, unique hostessing and musicians to make sure your event
                  one to remember.
                </p>
                <br />
                <p>
                  We have performed shows such as Moulin Rouge, Gatsby 1920's,
                  Burlesque, Cheerleaders and Vegas Show Girls. We are very
                  versatile performing at a range of venues such as charities,
                  club nights, restaurants and arenas in the UK and over seas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-black has-extra-margin-bottom">
        <div className="container">
          <div className="columns is-vcentered is-mobile is-centered is-wrapped">
            <div className="column is-2">
              <Img
                fluid={data.indigo.childImageSharp.fluid}
                alt="Indigo at the o2"
              />
            </div>
            <div className="column is-2">
              <Img fluid={data.modball.childImageSharp.fluid} alt="Modball" />
            </div>
            <div className="column is-2">
              <Img fluid={data.limahl.childImageSharp.fluid} alt="Limahl" />
            </div>
            <div className="column is-2">
              <Img fluid={data.uncommon.childImageSharp.fluid} alt="UNCOMMON" />
            </div>
            <div className="column is-2">
              <Img
                fluid={data.youmebrunch.childImageSharp.fluid}
                alt="youmebrunch"
              />
            </div>
            <div className="column is-2">
              <Img
                fluid={data.nightcap.childImageSharp.fluid}
                alt="Night Cap"
              />
            </div>
            <div id="playhxuse" className="column is-2">
              <Img
                fluid={data.playhxuse.childImageSharp.fluid}
                alt="play hxuse"
              />
            </div>
            <div className="column is-2">
              <Img
                fluid={data.skinnykitchen.childImageSharp.fluid}
                alt="skinny kitchen"
              />
            </div>
            <div className="column is-2" id="aseye">
              <Img fluid={data.aseye.childImageSharp.fluid} alt="aseye" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Contact />
    </>
  );
};

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    modballRally: file(relativePath: { eq: "modball-rally.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    modball: file(relativePath: { eq: "modball.png" }) {
      childImageSharp {
        fluid(maxWidth: 199) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    indigo: file(relativePath: { eq: "indigo.png" }) {
      childImageSharp {
        fluid(maxWidth: 312) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    uncommon: file(relativePath: { eq: "UNCOMMON.png" }) {
      childImageSharp {
        fluid(maxWidth: 294) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    limahl: file(relativePath: { eq: "limahl.png" }) {
      childImageSharp {
        fluid(maxWidth: 318) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    moulin: file(relativePath: { eq: "moulin.png" }) {
      childImageSharp {
        fluid(maxWidth: 593) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    showGirls: file(relativePath: { eq: "showgirls.png" }) {
      childImageSharp {
        fluid(maxWidth: 494) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    podiumDancer: file(relativePath: { eq: "podium.png" }) {
      childImageSharp {
        fluid(maxWidth: 645) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    team: file(relativePath: { eq: "team.png" }) {
      childImageSharp {
        fluid(maxWidth: 660) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    dancer: file(relativePath: { eq: "dancer.png" }) {
      childImageSharp {
        fluid(maxWidth: 850) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    starPink: file(relativePath: { eq: "star-pink.png" }) {
      childImageSharp {
        fluid(maxWidth: 139) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    starIvory: file(relativePath: { eq: "star-ivory.png" }) {
      childImageSharp {
        fluid(maxWidth: 114) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    sweetGirls: file(relativePath: { eq: "sweet-girls.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 593) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    slider1: file(relativePath: { eq: "slider-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    slider2: file(relativePath: { eq: "slider-2.gif" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    youmebrunch: file(relativePath: { eq: "youmebrunch.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    nightcap: file(relativePath: { eq: "nightcap.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    skinnykitchen: file(relativePath: { eq: "skinnykitchen.webp" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    playhxuse: file(relativePath: { eq: "playhxuse.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    aseye: file(relativePath: { eq: "aseye.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
